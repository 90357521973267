import {
  AnalyticsEmbeddableParams,
  CampaignParams,
  CandidateParams,
  JobListingPipelineParams,
  JobListingStageParams,
  JobListingViewParams,
  JobseekerDetailsPageParams,
  ReportParams,
  RequisitionParams,
} from './routes.types'

const externalUrls = {
  demo: 'https://help.scalis.ai/meetings/brandonamoroso/scalis-intro',
  employerSupport: 'https://help.scalis.ai/_hcms/mem/login?redirect_url=https%3A%2F%2Fhelp.scalis.ai%2Fcustomer-portal',
  support: 'https://help.scalis.ai',
  linkedin: 'https://linkedin.com/company/scalisai',
  xTwitter: 'https://twitter.com/scalis_ai',
} as const

const authRoutes = {
  login: '/login',
  signup: '/signup',
  social_auth_completed: '/social-auth-completed',
  signupEmployer: '/signup?employer',
  signupJobSeeker: '/signup?job_seeker',
  email_validation: '/email-validation',
  forgot_password: '/forgot-password',
  account_selector: '/account-selector',
} as const

const userRoutes = {
  profile: '/user/profile',
  settings: {
    base: '/user-settings',
    account_information: '/user-settings/account-information',
    sign_in_and_security: '/user-settings/sign-in-and-security',
  },
} as const

const applicationFlowRoutes = {
  name_resume: '/application/name-resume',
  basic_information: '/application/basic-information',
  profile: '/application/profile',
  screening_questions: '/application/screening-questions',
} as const

const workspaceRoutes = {
  create: '/workspace/create',
  dashboard: '/company/home/active', // TODO: Replace with dashboard route when page is ready
  list: '/workspace/select',
} as const

const jobseekerRoutes = {
  base: '/jobseeker',
  messaging: '/jobseeker/messaging',
  profile: '/jobseeker/profile',
  dashboard: '/jobseeker/dashboard-v2/applications',
  applicationDetails: ({ id }: JobseekerDetailsPageParams) => `/jobseeker/dashboard-v2/application/${id}`,
} as const

const jobListingRoutes = {
  view: ({ requisitionId }: JobListingViewParams) => `/company/requisition/${requisitionId}/requisition-details`,
  pipeline: ({ requisitionId }: JobListingPipelineParams) => `/company/pipeline/${requisitionId}`,
  stage: ({ requisitionId, milestoneId, stageId }: JobListingStageParams) =>
    `/company/pipeline/${requisitionId}/milestone/${milestoneId}/stage/${stageId}`,
} as const

const companyRoutes = {
  base: '/company',
  dashboard: '/company/home/active',
  active: '/company/home/active',
  messaging: '/company/messaging',
  profile: '/company/profile',
  talent_pool: '/company/talent-pool',
  calendar: '/company/calendar',
  analytics: '/company/analytics',
  analytics_dashboard: ({ id }: AnalyticsEmbeddableParams) => `/company/analytics/${id}`,
  reports: '/company/reports',
  referrals: '/company/referrals',
  referral_submission: '/company/referral-submission',
  report: ({ reportId }: ReportParams) => `/company/report/${reportId}`,
  candidate: ({ candidateProfileId }: CandidateParams) => `/company/candidate/${candidateProfileId}`,
  settings: {
    general: {
      company_information: '/company/account/general/company-information',
      offices: '/company/account/general/offices',
      departments: '/company/account/general/departments',
    },
    document_templates: '/company/account/document-templates',
    custom_fields: '/company/account/custom-fields',
    user_management: '/company/account/user-management',
    billing: '/company/account/billing',
    global_listing_setup: {
      root: '/company/account/global-listing-setup',
      listing_statements: '/company/account/global-listing-setup/listing-statements',
      screening_questions: '/company/account/global-listing-setup/screening-questions',
    },
    security: '/company/account/security',
    calendar_settings: '/company/account/calendar-settings',
    email_templates: '/company/account/email-templates',
    career_page_setup: '/company/account/career-page-setup',
    integrations: '/company/account/integrations',
    pipeline_templates: {
      active: '/company/account/pipeline-templates/active',
      archived: '/company/account/pipeline-templates/archived',
    },
    scorecard_templates: '/company/account/scorecard-templates',
  },
} as const

const campaignRoutes = {
  create: ({ requisitionId }: CampaignParams) => `/campaign/create/${requisitionId}`,
} as const

const requisitionRoutes = {
  new: '/company/requisition/new/job-details',
  edit: ({ requisitionId }: RequisitionParams) => `/company/requisition/${requisitionId}`,
  jobDetails: '/job-details',
  jobDescription: '/job-description',
  skillsAndPreferences: '/skills-and-preferences',
  listings: '/listings',
  pipelineSetup: '/pipeline-setup',
  postJob: '/post-job',
  requisitionDetails: '/requisition-details',
} as const

const landingRoutes = {
  forEmployers: '/for-employers',
  forJobSeekers: '/for-job-seekers',
  findJobs: '/jobs',
  findTalent: '/for-employers',
  schedule: {
    demo: externalUrls.demo,
  },
  resources: {
    forCompanies: {
      signUp: authRoutes.signupEmployer,
      postJob: '/for-employers',
      learnMore: '/for-employers',
      employerSupport: externalUrls.employerSupport,
    },
    forJobSeekers: {
      signUp: authRoutes.signupJobSeeker,
      searchJobs: '/jobs',
      learnMore: '/for-job-seekers',
    },
    help: {
      contactUs: 'mailto:support@scalis.ai',
      support: externalUrls.support,
    },
  },
  legal: {
    termsOfService: '/public/tos',
    privacyPolicy: '/public/pp',
    dpa: '/public/dpa',
  },
  social: {
    linkedin: externalUrls.linkedin,
    xTwitter: externalUrls.xTwitter,
  },
} as const

export const Routes = {
  home: '/',
  auth: authRoutes,
  user: userRoutes,
  application_flow: applicationFlowRoutes,
  jobs_view: '/jobs',
  job_details: '/job/:id',
  jobs_embed: '/jobs/embed',
  job_listing: jobListingRoutes,
  workspace: workspaceRoutes,
  jobseeker: jobseekerRoutes,
  company: companyRoutes,
  contact: '/contact',
  requisition: requisitionRoutes,
  landing: landingRoutes,
  campaign: campaignRoutes,
  error: '/error',
} as const
